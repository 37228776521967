import { useEffect, useRef, useState } from "react";
import video from "../assets/wpe.mp4";

function VideoComp({ lang }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const videoref = useRef();

    useEffect(() => {
        generateThumbnail();
    }, []);

    const generateThumbnail = () => {
        const videoElement = document.createElement('video');
        videoElement.src = video;
        videoElement.crossOrigin = 'anonymous'; // If your video is hosted on a different domain

        videoElement.addEventListener('loadeddata', () => {
            videoElement.currentTime = 1; // Set to 1 second (0:01)
        });

        videoElement.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            setThumbnail(canvas.toDataURL());
        });
    };

    const startVideo = () => {
        videoref.current.play();
        setIsPlaying(true);
    };

    const handleVideoEnd = () => {
        setIsPlaying(false);
    };

    let locale = lang.video;

    return (
        <>
            <div className="video-comp" id="video">
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{ __html: locale.heading }} />                        

                    <div className="video-container">
                        <video
                            src={video}
                            ref={videoref}
                            onEnded={handleVideoEnd}
                            onPause={() => setIsPlaying(false)}
                            onPlay={() => setIsPlaying(true)}
                            controls
                            preload="auto"
                            poster={thumbnail}
                        >
                        </video>

                        {!isPlaying && (
                            <div
                                className="play-button"
                                id="playButton"
                                onClick={startVideo}
                                aria-label="Play video"
                                style={{ display: isPlaying ? "none" : "block" }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>

            <div class="scrolling-container">
                <div class="scrolling-text">
                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>
                    
                    {locale.line}
                    <span>/</span>
                    
                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                </div>
            </div>
        </>
    );
}

export default VideoComp;
